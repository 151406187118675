<template>
  <div class="inspiration-wrap">
    <div class="inspiration-container">
      <div class="inspiration-top">
        <h1 class="inspiration-title">Choose Your Inspiration</h1>
      </div>

      <div class="loading" v-if="isLoading">
        <Loading />
      </div>

      <div class="max-xl:px-5 xl:px-32 w-full">
        <div class="max-w-rifyo mx-auto">
          <div
            class="grid grid-cols-4 md:grid-cols-12 gap-x-6 gap-y-4"
            v-if="!isLoading"
          >
            <inspiration-card
              v-for="pv in inspirationList"
              :slug="pv.slug"
              :key="pv.id"
              :desc="pv.title"
              :color="pv.color"
              :imageSrc="pv.image"
              :tag="'#ED701B'"
              :image_src="'/playground_assets/inspiration_orange.png'"
              rootClassName="rootClassName"
            ></inspiration-card>
          </div>
        </div>
      </div>
      <!--<div class="inspiration-container1">
        <button class="inspiration-button button">
          <span class="inspiration-text1">See more</span>
        </button>
      </div> -->
    </div>
    <div @click="closeMsg()" class="alert-err">
      <XClose v-if="isError" :text="msg_err" />
    </div>
    <Footer />
  </div>
</template>

<script>
  import InspirationCard from '../components/inspiration-card';
  import Footer from '../components/footer';
  import Api from '../api';
  import XClose from '../components/x-close';
  import Loading from '../components/Loading.vue';

  export default {
    name: 'Inspiration',
    props: {},
    data() {
      return {
        inspirationList: [],
        msg_err: null,
        isError: false,
        isLoading: false,
      };
    },
    created() {
      this.getInspiration();
    },
    methods: {
      closeMsg() {
        this.msg_err = null;
        this.isError = false;
      },
      async getInspiration() {
        try {
          this.isLoading = true;
          const response = await this.$http.get(Api.inspirationUrl);
          this.isLoading = false;
          this.inspirationList = response.data.data;
        } catch (error) {
          this.isLoading = false;
          this.msg_err = error.message;
          this.isError = true;
          setTimeout(() => {
            this.isError = false;
          }, 3000);
        }
      },
    },
    components: {
      InspirationCard,
      Footer,
      XClose,
      Loading,
    },
  };
</script>

<style scoped>
  .loading {
  }
  .alert-err {
    position: fixed;
    right: 20px;
    bottom: 30px;
    width: 300px;
    z-index: 10000;
    background: #fff;
  }
  .inspiration-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
  }
  .inspiration-top {
    flex: 0 0 auto;
    width: 100%;
    height: 100px;
    display: flex;
    margin-top: 110px;
    align-items: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: center;
  }
  .inspiration-title {
    color: #1f252c;
    font-size: 48px;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
  }
  .inspiration-text {
    color: #524538;
    font-size: 48px;
    font-family: freehand;
    margin-left: var(--dl-space-space-oneandhalfunits);
  }
  .inspiration-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
    row-gap: 24px;
    column-gap: 16px;
  }

  .inspiration-row > .rootClassName {
    flex: 0 1 32%; /*grow | shrink | basis */
    height: 130px;
  }

  .inspiration-container1 {
    flex: 0 0 auto;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .inspiration-button {
    font-family: Montserrat;
    border-width: 0px;
    border-radius: 0px;
    background-color: transparent;
  }
  .inspiration-text1 {
    color: #524538;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.24em;
  }

  @media (max-width: 991px) {
    /* mobile */
    .inspiration-row {
      padding-left: 16px;
      padding-right: 16px;
      row-gap: 10px;
      column-gap: 10px;
    }
    .inspiration-title,
    .inspiration-text {
      font-size: 24px;
    }
    .inspiration-top {
      padding: 16px;
      margin-top: 80px;
      margin-bottom: 0;
    }
    .inspiration-row > .rootClassName {
      flex: 0 1 100%; /*grow | shrink | basis */
    }
    /* mobile */
  }
</style>
